import axios from "axios";

export default class ContestService {
    static async getAllContests() {
        return await axios.get("/api/contests/all", {
            headers: {"Authorization": this.basicAuth()}
        });
    }

    static async getRunningContests() {
        return await axios.get("/api/contests/running", {
            headers: {"Authorization": this.basicAuth()}
        });
    }

    static async getFinishedContests() {
        return await axios.get("/api/contests/finished", {
            headers: {"Authorization": this.basicAuth()}
        });
    }

    static async changeStatus(contestId) {
        return await axios.get("/api/contests/changeStatus", {
            params: {contestId},
            headers: {"Authorization": this.basicAuth()}
        });
    }

    static async createCodeforcesContest(data) {
        return await axios.post("/api/contests/create/codeforces", data, {
            headers: {"Authorization": this.basicAuth()}
        });
    }

    static async createEjudgeContest(data) {
        return await axios.post("/api/contests/create/ejudge", data, {
            headers: {"Authorization": this.basicAuth()}
        });
    }

    static basicAuth() {
        return "Basic " + JSON.parse(localStorage.getItem("user")).authData;
    }
}