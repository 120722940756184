import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import SubmissionService from "../services/SubmissionService";
import {Button, message, Table} from "antd";

const ContestPage = () => {
    const {contestId} = useParams();
    const [newSubmissions, setNewSubmissions] = useState([]);
    const [inProgressSubmissions, setInProgressSubmissions] = useState([]);
    const [processedSubmissions, setProcessedSubmissions] = useState([]);
    const [myInProgressSubmissions, setMyInProgressSubmissions] = useState([]);
    const [stateChanged, setStateChanged] = useState(true);
    const [allTeams, setAllTeams] = useState([]);

    const columns = [
        {
            title: "Internal ID",
            dataIndex: "internalId",
            key: "internalId"
        },
        {
            title: "Team",
            dataIndex: "userName",
            key: "userName",
            filters: allTeams,
            onFilter: (value, record) => record.userName.indexOf(value) === 0,
        },
        {
            title: "Problem",
            dataIndex: "problemId",
            key: "problemId"
        },
        {
            title: "Is first AC",
            dataIndex: "isFirstAc",
            key: "isFirstAc",
            render: (text) => String(text)
        },
        {
            title: "Submission time",
            dataIndex: "time",
            key: "time"
        }
    ];

    const fetchSubmissions = async () => {
        const fetchedNewSubmissions = await SubmissionService.getAllSubmissionsByContestIdAndStatus(contestId, "NOT_PROCESSED");
        const fetchedInProgressSubmissions = await SubmissionService.getAllSubmissionsByContestIdAndStatus(contestId, "IN_PROGRESS");
        const fetchedProcessedSubmissions = await SubmissionService.getAllSubmissionsByContestIdAndStatus(contestId, "PROCESSED");
        const fetchedMyInProgressSubmissions = await SubmissionService.getAllMySubmissionsByContestIdAndStatus(contestId, "IN_PROGRESS");

        setNewSubmissions(fetchedNewSubmissions.data.map(submissionData => ({
            ...submissionData,
            key: submissionData.id
        })));
        setInProgressSubmissions(fetchedInProgressSubmissions.data.map(submissionData => ({
            ...submissionData,
            key: submissionData.id
        })));
        setProcessedSubmissions(fetchedProcessedSubmissions.data.map(submissionData => ({
            ...submissionData,
            key: submissionData.id
        })));
        setMyInProgressSubmissions(fetchedMyInProgressSubmissions.data.map(submissionData => ({
            ...submissionData,
            key: submissionData.id
        })));

        setAllTeams(fetchedNewSubmissions.data.map(submissionData => submissionData.userName).filter((v, i, a) => a.indexOf(v) === i).map(submissionData => ({
            text: submissionData,
            value: submissionData
        })));
    };

    useEffect(() => {
        if (stateChanged) {
            fetchSubmissions().catch(console.log);
            message.info("Submissions list updated!")
        }

        const interval = setInterval(() => {
            fetchSubmissions().catch(console.log);
            message.info("Submissions list updated!")
        }, 10000);
        setStateChanged(false);
        return () => clearInterval(interval);
    }, [stateChanged]);

    return (
        <div>
            <h1>My submissions in progress:</h1>
            <Table columns={[
                ...columns,
                {
                    title: "Processed by",
                    key: "processedBy",
                    render: (_, record) => (<>{record.processedBy.displayedName}</>)
                },
                {
                    title: "Cancel processing",
                    key: "startProcessing",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            SubmissionService.cancelProcessing(record.id);
                            setStateChanged(true);
                        }}>Cancel processing</Button>
                    )
                },
                {
                    title: "Finish processing",
                    key: "startProcessing",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            SubmissionService.finishProcessing(record.id);
                            setStateChanged(true);
                        }}>Finish processing</Button>
                    )
                },
            ]}
                   dataSource={myInProgressSubmissions}
                   rowClassName={(record) => {
                       return record.isFirstAc ? "data-row first-ac" : "data-row"
                   }}/>

            <h1>Unprocessed submissions:</h1>
            <Table columns={[
                ...columns,
                {
                    title: "Start processing",
                    key: "startProcessing",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            SubmissionService.startProcessing(record.id);
                            setStateChanged(true);
                        }}>Start processing</Button>
                    )
                }
            ]}
                   dataSource={newSubmissions}
                   rowClassName={(record) => {
                       return record.isFirstAc ? "data-row first-ac" : "data-row"
                   }}
            />

            <h1>Submissions in progress:</h1>
            <Table columns={[
                ...columns,
                {
                    title: "Processed by",
                    key: "processedBy",
                    render: (_, record) => (<>{record.processedBy.displayedName}</>)
                },
                {
                    title: "Cancel processing",
                    key: "startProcessing",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            SubmissionService.cancelProcessing(record.id);
                            setStateChanged(true);
                        }}>Cancel processing</Button>
                    )
                },
                {
                    title: "Finish processing",
                    key: "startProcessing",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            SubmissionService.finishProcessing(record.id);
                            setStateChanged(true);
                        }}>Finish processing</Button>
                    )
                },
            ]} dataSource={inProgressSubmissions}
                   rowClassName={(record) => {
                       return record.isFirstAc ? "data-row first-ac" : "data-row"
                   }}/>

            <h1>Processed submits:</h1>
            <Table columns={[
                ...columns,
                {
                    title: "Processed by",
                    key: "processedBy",
                    render: (_, record) => (<>{record.processedBy.displayedName}</>)
                },
                {
                    title: "Cancel processing",
                    key: "cancelProcessingFromFinished",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            SubmissionService.cancelProcessingFromFinished(record.id);
                            setStateChanged(true);
                        }}>Cancel processing</Button>
                    )
                },
            ]} dataSource={processedSubmissions} rowClassName={(record) => {
                return record.isFirstAc ? "data-row first-ac" : "data-row"
            }}/>
        </div>
    );
};

export default ContestPage;