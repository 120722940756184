import React from 'react';
import {Button, Form, Input, message} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import ContestService from "../services/ContestService";

const CreateContest = () => {
    const navigate = useNavigate();
    const {contestType} = useParams();

    const onFinish = (data) => {
        if (contestType === "codeforces") {
            ContestService.createCodeforcesContest(data).catch(() => message.error("You do not have access sto this operation"));
        }
        if (contestType === "ejudge") {
            console.log(data);
            ContestService.createEjudgeContest(data).catch(() => message.error("You do not have access sto this operation"));
        }
        navigate("/");
    };

    return (
        <Form name="createContestForm" initialValues={{remember: false}} onFinish={onFinish} autoComplete="off"
            labelCol={{span: 4}} wrapperCol={{span: 16}} labelWrap={true}>
            <Form.Item label="Contest name" name="name" rules={[
                {required: true, message: "Please, input contest name!"}
            ]}>
                <Input/>
            </Form.Item>
            {
                contestType === "codeforces" &&
                    <>
                        <Form.Item label="Contest freeze time (number of sec. after the contest start to freeze)"
                                   name="freezeTime" rules={[
                            {required: true, message: "Please, input contest freeze time!"},
                            {pattern: "[1-9]([0-9]*)", message: "Please, input a positive integer!"} // TODO: check regex
                        ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Contest ID in Codeforces" name="contestId" rules={[
                            {required: true, message: "Please, input contest ID!"},
                            {pattern: "[1-9]([0-9]*)", message: "Please, input a positive integer!"} // TODO: check regex and warning
                        ]}>
                            <Input/>
                        </Form.Item>
                    </>
            }
            {
                contestType === "ejudge" &&
                    <>
                        <Form.Item label="URL to external XML log" name="url" rules={[
                            {required: true, message: "Please, input URL to external XML log"},
                            {type: "url", message: "Please, input URL"}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </>
            }
            <Form.Item wrapperCol={{offset: 4}}>
                <Button type="primary" htmlType="submit">
                    Create
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateContest;