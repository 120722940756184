import React, {useEffect, useState} from 'react';
import UserService from "../services/UserService";
import {Button, message, Table} from "antd";
import {Link, useNavigate} from "react-router-dom";

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const columns = [
        {title: "ID", key: "id", dataIndex: "id"},
        {title: "Login", key: "login", dataIndex: "login"},
        {title: "Displayed name", key: "displayedName", dataIndex: "displayedName"},
        {title: "Edit", key: "edit", render: (_, record) => (
            <Button type="primary" onClick={() => {
                navigate("/users/" + record.id);
            }}>Edit</Button>
        )}
    ];

    useEffect(() => {
        const fetchUsers = async () => {
            const fetchedUsers = await UserService.getAllUsers().catch(() => {
                message.error("You do not have access to this page");
                navigate("/");
            });
            setUsers(fetchedUsers.data.map(user => ({...user, key: user.id})));
        }
        fetchUsers();
    }, []);

    return (
        <div>
            <Table columns={columns} dataSource={users}/>
        </div>
    );
};

export default UsersPage;