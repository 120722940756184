import React, {useEffect} from 'react';
import {useAuth} from "../context/AuthProvider";
import {Navigate} from "react-router";

const LogoutPage = () => {
    const {logout} = useAuth();

    useEffect(() => {
        logout();
    }, []);

    return (
        <Navigate to="/" replace/>
    );
};

export default LogoutPage;