import axios from "axios";

export default class SubmissionService {
    static async getAllSubmissionsByContestId(contestId) {
        return await axios.get("/api/submissions/allByContest", {
            params: {contestId}
        });
    }

    static async getAllSubmissionsByContestIdAndStatus(contestId, status) {
        return await axios.get("/api/submissions/allByContestAndStatus", {
            params: {contestId, status}
        });
    }

    static async getAllMySubmissionsByContestIdAndStatus(contestId, status) {
        return await axios.get("/api/submissions/allByContestAndStatusAndUser", {
            params: {contestId, status}
        });
    }

    static async startProcessing(submissionId) {
        return await axios.get("/api/submissions/startProcessing", {
            params: {submissionId}
        })
    }

    static async cancelProcessing(submissionId) {
        return await axios.get("/api/submissions/cancelProcessing", {
            params: {submissionId}
        })
    }

    static async finishProcessing(submissionId) {
        return await axios.get("/api/submissions/finishProcessing", {
            params: {submissionId}
        })
    }

    static async cancelProcessingFromFinished(submissionId) {
        return await axios.get("/api/submissions/cancelProcessingFromFinished", {
            params: {submissionId}
        });
    }
}