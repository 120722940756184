import React from 'react';
import {BrowserRouter, HashRouter, Link, Navigate, Route, Routes} from "react-router-dom";
import ContestsList from "./ContestsList";
import CreateContest from "./CreateContest";
import ContestPage from "./ContestPage";
import RegisterPage from "./RegisterPage";
import UsersPage from "./UsersPage";
import EditUserPage from "./EditUserPage";
import LoginPage from "./LoginPage";
import {useAuth} from "../context/AuthProvider";
import LogoutPage from "./LogoutPage";
import SubMenu from "antd/es/menu/SubMenu";
import {Divider, Layout, Menu} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";

const Navigation = () => {
    const {isAuthenticated} = useAuth();

    if (!isAuthenticated()) {
        return (
            <HashRouter>
                <Layout style={{minHeight:"100vh"}}>
                    <Header>
                        <Menu mode="horizontal" theme="dark" items={[
                            {
                                label: (<Link to="/login">Login</Link>),
                                key: "login"
                            },
                            {
                                label: (<Link to="/register">Register</Link>),
                                key: "register"
                            }
                        ]}/>
                    </Header>
                    <Content style={{ padding: '0 50px' }}>
                        <div
                            className="site-layout-content"
                        >
                            <Routes>
                                <Route path="/login" element={<LoginPage/>}/>
                                <Route path="/register" element={<RegisterPage/>}/>
                                <Route path="*" element={<Navigate to="/login" replace/>}/>
                            </Routes>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        <Divider/>
                        ICPC Baloons Service ©2022-2023 Created by Mike Perveev
                    </Footer>
                </Layout>
            </HashRouter>
        );
    } else {
        return (
            <HashRouter>
                <Layout style={{minHeight:"100vh"}}>
                    <Header>
                        <Menu mode="horizontal" theme="dark" items={[
                            {
                                label: (<Link to="/">Main page</Link>),
                                key: "main"
                            },
                            {
                                label: "Add contest",
                                key: "contests",
                                children: [
                                    {
                                        label: (<Link to="/create/ejudge">Ejudge contest</Link>),
                                        key: "ejudge"
                                    },
                                    {
                                        label: (<Link to="/create/codeforces">Codeforces contest</Link>),
                                        key: "codeforces"
                                    }
                                ]
                            },
                            {
                                label: (<Link to="/users">Users</Link>),
                                key: "users"
                            },
                            {
                                label: (<Link to="/logout">Logout</Link>),
                                key: "logout"
                            }
                        ]}/>
                    </Header>
                    <Content style={{ padding: '0 50px' }}>
                        <div
                            className="site-layout-content"
                        >
                            <Routes>
                                <Route path="/" element={<ContestsList/>}/>
                                <Route path="/create/:contestType" element={<CreateContest/>}/>
                                <Route path="/contests/:contestId" element={<ContestPage/>}/>
                                <Route path="/users" element={<UsersPage/>}/>
                                <Route path="/users/:userId" element={<EditUserPage/>}/>
                                <Route path="/logout" element={<LogoutPage/>}/>
                                <Route path="*" element={<Navigate to="/" replace/>}/>
                            </Routes>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        <Divider/>
                        ICPC Baloons Service ©2022-2023 Created by Mike Perveev
                    </Footer>
                </Layout>
            </HashRouter>
        );
    }
};

export default Navigation;