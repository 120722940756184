import React from 'react';
import {useParams} from "react-router-dom";

const EditUserPage = () => {
    const {userId} = useParams();

    return (
        <div>
            {userId}
        </div>
    );
};

export default EditUserPage;