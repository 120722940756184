import React, {useContext, useEffect, useState} from 'react';

const AuthContext = React.createContext(null);

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(localStorage.getItem("user"));
    }, []);

    const getUser = () => {
        return JSON.parse(localStorage.getItem("user"));
    }

    const isAuthenticated = () => {
        return localStorage.getItem("user") !== null;
    }

    const login = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
    }

    const logout = () => {
        localStorage.removeItem("user");
        setUser(null);
    }

    return (
        <AuthContext.Provider value={{user, getUser, isAuthenticated, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

export function useAuth() {
    return useContext(AuthContext);
}

export {AuthProvider};