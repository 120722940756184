import axios from "axios";

export default class AuthService {
    static async register(data) {
        return await axios.post("/api/auth/register", data);
    }

    static async login(data) {
        return await axios.post("/api/auth/login", data);
    }
}