import React from 'react';
import {useEffect, useState} from "react";
import ContestService from "../services/ContestService";
import {Button, message, Table} from "antd";
import {Link, useNavigate} from "react-router-dom";

const ContestsList = () => {
    const [runningContests, setRunningContests] = useState([]);
    const [finishedContests, setFinishedContests] = useState([]);
    const [stateChanged, setStateChanged] = useState(false);
    const navigate = useNavigate();

    const bothColumns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Contest name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Contest type",
            dataIndex: "type",
            key: "type"
        }
    ];

    useEffect(() => {
        const fetchContests = async () => {
            const fetchedRunningContests = await ContestService.getRunningContests();
            const fetchedFinishedContests = await ContestService.getFinishedContests();

            console.log(fetchedRunningContests.status);
            console.log(fetchedRunningContests.statusText);

            setRunningContests(fetchedRunningContests.data.map(contestInfo => ({...contestInfo, key: contestInfo.id})));
            setFinishedContests(fetchedFinishedContests.data.map(contestInfo => ({...contestInfo, key: contestInfo.id})));
            setStateChanged(false);
        };

        fetchContests().catch(e => {
            if (e.response.status === 401) {
                navigate("/login");
            }
        });
    }, [stateChanged]);

    return (
        <div>
            <h1>Running contests:</h1>
            <Table columns={[
                ...bothColumns,
                {
                    title: "Stop updating",
                    key: "stopUpdating",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            ContestService.changeStatus(record.id).catch(() => message.error("You do not have access sto this operation"));
                            setStateChanged(true);
                        }}>Stop updating</Button>
                    )
                },
                {
                    title: "Contest status",
                    key: "status",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            navigate("/contests/" + record.id);
                        }}>Check status</Button>
                    )
                }
            ]} dataSource={runningContests}/>

            <h1>Other contests:</h1>
            <Table columns={[
                ...bothColumns,
                {
                    title: "Start updating",
                    key: "startUpdating",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            ContestService.changeStatus(record.id).catch(() => message.error("You do not have access sto this operation"));
                            setStateChanged(true);
                        }}>Start updating</Button>
                    )
                },
                {
                    title: "Contest status",
                    key: "status",
                    render: (_, record) => (
                        <Button type="primary" onClick={() => {
                            navigate("/contests/" + record.id);
                        }}>Check status</Button>
                    )
                }
            ]} dataSource={finishedContests}/>
        </div>
    );
};

export default ContestsList;