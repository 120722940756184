import './App.css';
import Navigation from "./components/Navigation";
import {AuthProvider} from "./context/AuthProvider";

function App() {
    return (
        <AuthProvider>
            <div className="App">
                <Navigation/>
            </div>
        </AuthProvider>
    );
}

export default App;
