import React from 'react';
import {Button, Form, Input} from "antd";
import Password from "antd/es/input/Password";
import AuthService from "../services/AuthService";
import {useNavigate} from "react-router-dom";

const RegisterPage = () => {
    const navigate = useNavigate();

    const onFinish = (data) => {
        AuthService.register(data);
        navigate("/");
    };

    return (
        <Form name="registrationForm" initialValues={{remember: false}} onFinish={onFinish} autoComplete="off"
            labelCol={{span: 3}} wrapperCol={{span: 8}}>
            <Form.Item label="Login" name="login" rules={[
                {required: true, message: "Please, input login!"}
            ]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[
                {required: true, message: "Please, input password!"}
            ]}>
                <Password/>
            </Form.Item>
            <Form.Item label="Displayed name" name="displayedName" rules={[
                {required: true, message: "Please, input displayed name"}
            ]}>
                <Input/>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 3}}>
                <Button type="primary" htmlType="submit">
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RegisterPage;