import React from 'react';
import {Button, Form, Input} from "antd";
import Password from "antd/es/input/Password";
import {useNavigate} from "react-router-dom";
import AuthService from "../services/AuthService";
import {useAuth} from "../context/AuthProvider";

const LoginPage = () => {
    const navigate = useNavigate();
    const {isAuthenticated, login} = useAuth();

    const onFinish = (data) => {
        AuthService.login(data).then(response => {
            const {id, displayedName, roles} = response.data;
            const authData = window.btoa(data.login + ":" + data.password);
            const user = {id, displayedName, roles, authData};
            login(user);
        }).catch(console.log);
        navigate("/");
    };

    return (
        <Form name="loginForm" initialValues={{remember: false}} onFinish={onFinish} autoComplete="off"
              labelCol={{span: 2}} wrapperCol={{span: 8}}>
            <Form.Item label="Login" name="login" rules={[
                {required: true, message: "Please, input login!"}
            ]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[
                {required: true, message: "Please, input password!"}
            ]}>
                <Password/>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 2}}>
                <Button type="primary" htmlType="submit">
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
};

export default LoginPage;